import { useStore } from "@nanostores/react";
import { incomingCallDataStore } from "@root/framework/stores/calls-store";
import { Amplify } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState, generateClient } from "aws-amplify/api";
import { Observable } from 'rxjs';
import { Hub } from 'aws-amplify/utils';
import { incomingCallSubscription } from "@root/graphql/subscriptions";
import { userRootDataStore } from "@root/framework/stores/user-store";
import { accessTokenStore } from "@root/framework/stores/app-store";
import type { CallStatusTypes } from "@root/framework/enums/call-status";
import { useEffect } from "react";

const AppsyncListener = () => {

    const $userRootDataStore = useStore(userRootDataStore);
    const $accessTokenStore = useStore(accessTokenStore);
            
    Amplify.configure({
        ...Amplify.getConfig(),
        API: {
            GraphQL: {
                endpoint: import.meta.env.PUBLIC_GRAPHQL_API_ENDPOINT,
                region: 'sa-east-1',
                defaultAuthMode: 'lambda'
            }
        }
    });
            
    Hub.listen('api', (data: any) => {
        const { payload } = data;
        if (payload.event === CONNECTION_STATE_CHANGE) {
            const connectionState = payload.data.connectionState as ConnectionState;
            switch (connectionState) {
                case ConnectionState.Connected:
                    console.log("Appsync Service Connected");
                    break;
                case ConnectionState.Disconnected:
                    console.log("Appsync Service Disconnected");
                    break;
            }

        }
    });

    useEffect(() => {
        if (!$userRootDataStore?.id || $accessTokenStore.length <= 2) return;

        const userCalling = generateClient({
            authMode: 'lambda',
            authToken: $accessTokenStore
        }).graphql({
            query: incomingCallSubscription,
            variables: {
                name: `v1/users/${$userRootDataStore?.id}`
            },
        }) as Observable<any>;

        userCalling.subscribe({
            next: (message: any) => {
                const eventData = JSON.parse(message.data.subscribe.data)
                switch (eventData.type as CallStatusTypes) {
                    case "INCOMING_CALL":
                        console.log(eventData)
                        proccessCall(eventData.type, eventData.data.incomingCallData)
                        break;
                    case "LOST_CALL":
                        proccessCall(eventData.type, eventData.data.lostCallData)
                        break;
                }
            },
            error: (error: any) => console.log(error)
        });

    }, [$userRootDataStore, $accessTokenStore])



    const proccessCall = (status: string, callData: any) => {
        incomingCallDataStore.set({
            ...callData,
            callStatus: status,
        })
    };
}

export default AppsyncListener